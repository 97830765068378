<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                 
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                  <div class="wrapper">
                      
                  <div class="contact-wrapper">
                      <div class="mail-us">
                        <h2>We'd love to hear from you</h2>
                        <a href="mailto:yangchenphughss@education.gov.bt" target="_blank">Mail Us</a>                      
                      </div>
                     
                  </div>
                  <div class="map">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d14160.060256862633!2d89.63149318157444!3d27.46879037625919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d27.4712216!2d89.6339041!4m5!1s0x39e1946d80a04737%3A0xef2d7adfb1b04d64!2syhss!3m2!1d27.466705299999997!2d89.6465918!5e0!3m2!1sen!2sbt!4v1650957238245!5m2!1sen!2sbt" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
            
                  </div>
                 
                 
   
   
              </div>
              
          <div class="sidebar">
              <NonAcademicQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import Footer from "../../components/page/Footer.vue";







export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, Footer },
    setup(){
        const title= ref('Contact Us')
        return {title}
    }
  
}
</script>

<style scoped>
.mail-us{
    background: #f4f4f4;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mail-us h2{
    font-family: 'Rajdhani', sans-serif;
    color: var(--secondary);
}
.mail-us a{
    text-decoration: none;
    background: var(--secondary);
    padding: 0.3rem 0.5rem;
    color: var(--pure);
    transition: 0.5s ease all;
}
.mail-us a:hover{
    background: var(--primary);
}
.wrapper{
    display: grid;
    grid-template-columns: auto  ;
    align-items: center;
}
.contact-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    background: #f4f4f4;
}
.contact-wrapper .details li{
    list-style-type: none;
}
</style>